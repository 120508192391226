/*-
 * #%L
 * Sagae
 * %%
 * Copyright (C) 2020 - 2023 OFB
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import { UsersService } from "./../users/UsersService"
import {
  EvaluationDTO,
  NotificationPPResponseDTO,
  ProjetAnnuelForInstructionDTO,
  ProjetInscriptionDTO,
} from "./../../../model/bean/GeneratedDTOs"
import {
  EtablissementDTO,
  FilterDTO,
  PaginationParameter,
  PeriodDTO,
  ProjetAnnuelDTO,
  ProjetPatchDTO,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import { AbstractTypedRestService } from "@/services/rest/AbstractTypedRestService"
import ConversionUtils from "@/services/ConversionUtils"

export class ProjectService extends AbstractTypedRestService<ProjetAnnuelDTO> {
  static INSTANCE = new ProjectService()

  async getPeriods(projectId?: string): Promise<PeriodDTO[]> {
    return super.typedGetV2<PeriodDTO>("projets/" + projectId + "/periods", 2)
  }

  async getProjetAnnuel(annualProjectId?: string): Promise<ProjetInscriptionDTO> {
    return await super.typedGetSingleV2<ProjetInscriptionDTO>("projets/" + annualProjectId, 2)
  }

  isProjetReadOnly(
    user: UtilisateurDTO | undefined,
    projet: ProjetAnnuelDTO,
    shouldBeReadOnlyIfInPause: boolean
  ): boolean {
    // Vérifier si projet.anneeScolaireFin est défini et non nul
    if (projet.anneeScolaireFin) {
      // Check 1: in annee scolaire has ended (and hence there is a newer projet year) => readonly
      // endOfSchoolYear.getDate() +1 pour prendre compte le jour de la fin de l'année scolaire (31 juillet)
      const endOfSchoolYearIncluded = new Date(projet.anneeScolaireFin)
      endOfSchoolYearIncluded?.setDate(endOfSchoolYearIncluded.getDate() + 1)
      if (endOfSchoolYearIncluded?.getTime() <= Date.now()) {
        return true
      }
    }

    // Check 2: if project in pause/archive => readonly
    if ((projet.etat == "EN_PAUSE" && shouldBeReadOnlyIfInPause) || projet.etat == "ARCHIVE") {
      return true
    }

    // Check 3: if user is accompagnateur national or animateur from correct grae => not readonly
    if (!user) {
      return true
    }
    if (
      UsersService.INSTANCE.isUserAccompagnateurFromRegion(user, projet) &&
      user.profil != "ACCOMPAGNATEUR_REGIONAL"
    ) {
      return false
    } else {
      // Check 3 : user is a PP, can edit only if he is part of the project
      let allowedUserIds = projet.enseignants.map((e) => e.id)
      allowedUserIds = allowedUserIds.concat(projet.referents.map((r) => r.id))
      return allowedUserIds.indexOf(user.id) == -1
    }
  }

  async getPendingInvitationsToProject(): Promise<ProjetAnnuelDTO[]> {
    return super.typedGetV2<ProjetAnnuelDTO>(`projets/en-attente`, 2)
  }

  async getProjectHistory(annualProjectId?: string): Promise<ProjetAnnuelForInstructionDTO[]> {
    return super.typedGetV2<ProjetAnnuelForInstructionDTO>(
      `projets/` + annualProjectId + `/history`,
      2
    )
  }

  async getProjetsForPorteurProjet(
    user: UtilisateurDTO,
    filtersList: Array<FilterDTO>
  ): Promise<ProjetAnnuelDTO[]> {
    let profil = ""
    if (user.profil === "ENSEIGNANT") {
      profil = "enseignant"
    } else if (user.profil === "REFERENT") {
      profil = "referent"
    }
    const allParameter = { ...new PaginationParameter(), all: true, pageNumber: 1, pageSize: -1 }
    let projects = await super
      .typeGetPaginatedV2<ProjetAnnuelDTO>(
        `/projets/${profil}/${user.id}`,
        2,
        allParameter,
        filtersList
      )
      .then((result) => (result.elements ? result.elements : []))

    // Ref #8888 hide refused dossiers inscription from list
    projects = projects.filter(
      (p) => p.typeDossier != "DOSSIER_INSCRIPTION" || p.statut != "REFUSE"
    )
    return projects
  }

  async getEtablissementProjects(etablissement: EtablissementDTO): Promise<ProjetAnnuelDTO[]> {
    return super.typedGetV2("/projets/etablissement/" + etablissement.codeEtablissement, 2)
  }

  async getProjectsOfStructure(structureId: string): Promise<ProjetAnnuelDTO[]> {
    return super.typedGetV2("/projets/structure/" + structureId, 2)
  }

  async sendAnswersAboutPendingInvitations(
    projects: ProjetAnnuelDTO[],
    user: UtilisateurDTO
  ): Promise<void> {
    await Promise.all(
      projects.map(async (project) => {
        if (user.id && project.consentementValide.includes(user.id)) {
          await super.typedGetV2(
            "/projets/" + project.projetId + "/accept_project_inclusion/" + user.id,
            2
          )
        } else if (user.id && !project.consentementEnAttente.includes(user.id)) {
          await super.typedGetV2(
            "/projets/" + project.projetId + "/decline_project_inclusion/" + user.id,
            2
          )
        }
      })
    )
  }

  async sendAnswerAboutPendingInvitation(project: ProjetAnnuelDTO, userId: string): Promise<void> {
    if (project.consentementValide.includes(userId)) {
      await this.acceptProjetInclusion(project.projetId, userId)
    } else if (!project.consentementEnAttente.includes(userId)) {
      await this.declineProjetInclusion(project.projetId, userId)
    }
  }

  async acceptProjetInclusion(projectId: string, userId: string): Promise<void> {
    await super.typedGetV2("/projets/" + projectId + "/accept_project_inclusion/" + userId, 2)
  }

  async declineProjetInclusion(projectId: string, userId: string): Promise<void> {
    await super.typedGetV2("/projets/" + projectId + "/decline_project_inclusion/" + userId, 2)
  }

  async getNotificationPP(idGrae: string | undefined): Promise<NotificationPPResponseDTO[]> {
    let urlNotif = "/projets/notification-pp"
    if (idGrae) {
      urlNotif += "?idGrae=" + idGrae
    }
    return super.typedGetV2(urlNotif, 2)
  }

  async resetNotificationPP(idGrae: string | undefined): Promise<void> {
    let url = "/reset-notification-pp"
    if(idGrae) {
      url += "?idGrae=" + idGrae
    }
    await super.typedGetV2("/projets" + url, 2)
  }

  async createProject(project: ProjetAnnuelDTO): Promise<ProjetAnnuelDTO> {
    return super.typedPostV2("/projets/", 2, project)
  }

  async createProjectDemande(project: ProjetAnnuelDTO): Promise<ProjetAnnuelDTO> {
    return super.typedPostV2("/projets/demande", 2, project)
  }

  async patchProject(projectId: string, project: ProjetPatchDTO): Promise<ProjetPatchDTO> {
    return super.typedPatchV2("/projets/" + projectId, 2, project)
  }

  async deleteProject(projectId: string): Promise<void> {
    await super.typedDeleteV2("/projets/" + projectId, 2)
    return Promise.resolve()
  }

  async saveProject(project: ProjetAnnuelDTO): Promise<ProjetAnnuelDTO> {
    if (project.typeDossier == "DOSSIER_INSCRIPTION") {
      return await super.typedPutV2("projets/inscription/" + project.id, 2, project)
    } else {
      return await super.typedPutV2("projets/" + project.id, 2, project)
    }
  }

  async submitProjetInscription(project: ProjetInscriptionDTO): Promise<ProjetInscriptionDTO> {
    return super.typedPutV2("projets/inscription/" + project.id + "/submit", 2, project)
  }

  async submitProjetLabellisation(project: ProjetAnnuelDTO): Promise<ProjetAnnuelDTO> {
    return super.typedPutV2("projets/labellisations/" + project.id + "/submit", 2, project)
  }

  async unlockProject(projectId: string): Promise<ProjetAnnuelDTO> {
    return super.typedPutV2("projets/unlock/" + projectId, 2, new ProjetAnnuelDTO())
  }
  async unarchiveProject(projectId: string): Promise<ProjetAnnuelDTO> {
    return super.typedPutV2("projets/unarchive/" + projectId, 2, new ProjetAnnuelDTO())
  }

  async updateInstructeur(projetId: string, instructeur: UtilisateurDTO): Promise<UtilisateurDTO> {
    return super.typedPutV2("/projets/inscription/" + projetId + "/instructeur", 2, instructeur)
  }

  async removeInstructeur(
    projetId: string,
    instructeur: UtilisateurDTO
  ): Promise<UtilisateurDTO[]> {
    const instructeurId = instructeur.id
    return super.typedDeleteV2(
      "/projets/inscription/" + projetId + "/instructeur/" + instructeurId,
      2
    )
  }

  // VALID
  async updateEvaluation(
    projetAnnuel: ProjetAnnuelDTO,
    evaluation: EvaluationDTO
  ): Promise<EvaluationDTO> {
    return super.typedPutV2(
      "projets/labellisation/" + projetAnnuel.id + "/evaluation/" + evaluation.id,
      2,
      evaluation
    )
  }

  // VALID
  async updateQualifications(projetAnnuel: ProjetAnnuelDTO): Promise<ProjetAnnuelDTO> {
    return super.typedPutV2(
      "projets/labellisation/qualifications/" + projetAnnuel.id,
      2,
      projetAnnuel
    )
  }

  getProjectStatusLabel(projetAnnuel: ProjetAnnuelDTO, loggedUser: UtilisateurDTO): string {
    switch (loggedUser.profil) {
      case "REFERENT":
      case "ENSEIGNANT":
        switch (projetAnnuel.typeDossier) {
          // For PP dossier inscrition
          case "DOSSIER_INSCRIPTION":
            switch (projetAnnuel.statut) {
              case "BROUILLON":
                return "Brouillon"
              case "INSTRUCTION_TERMINEE":
              case "INSTRUCTION_EN_COURS":
              case "EN_ATTENTE":
                return "Inscription à l'étude"
              case "ACCEPTE":
                return "Inscription validée"
              case "ACCEPTE_SOUS_RESERVE_REFERENT":
                return "Inscription validée<br/>sous réserve"
              case "REFUSE":
                return "Inscription refusée"
              default:
                return projetAnnuel.statut
            }

          // For PP labellisation
          default:
            switch (projetAnnuel.statut) {
              case "BROUILLON":
                return "Projet en cours"
              case "INSTRUCTION_TERMINEE":
              case "INSTRUCTION_EN_COURS":
              case "EN_ATTENTE":
                if (projetAnnuel.typeDossier == "DOSSIER_LABELLISATION") {
                  return "Labellisation à l'étude"
                } else {
                  return "Renouvellement à l'étude"
                }
              case "ACCEPTE":
                if (projetAnnuel.typeDossier == "DOSSIER_LABELLISATION") {
                  return "Labellisé"
                } else {
                  return "Renouvelé"
                }
              case "LABELLISATION_EN_DEVENIR":
                return "Labellisé en devenir"
              case "REFUSE":
                return "Projet en cours"
              default:
                return projetAnnuel.statut
            }
        }

      default:
        switch (projetAnnuel.typeDossier) {
          // For accompagnateurs dossier inscription
          case "DOSSIER_INSCRIPTION":
            switch (projetAnnuel.statut) {
              case "BROUILLON":
                return "Brouillon"
              case "EN_ATTENTE":
                return "Instruction en attente"
              case "INSTRUCTION_TERMINEE":
              case "INSTRUCTION_EN_COURS":
                return "En cours d'instruction"
              case "ACCEPTE":
                return "Inscription validée"
              case "ACCEPTE_SOUS_RESERVE_REFERENT":
                return "Inscription validée<br/>sous réserve"
              case "REFUSE":
                return "Inscription refusée"
              default:
                break
            }
            break

          // For accompagnateurs labellisation
          default:
            switch (projetAnnuel.statut) {
              case "BROUILLON":
                // If project was labellised in the past, show the last labellisation result
                if (
                  projetAnnuel.lastEvaluationTypeDossier &&
                  projetAnnuel.lastEvaluationTypeDossier != "DOSSIER_INSCRIPTION"
                ) {
                  return this.getLabelForLastLabellisation(projetAnnuel)
                }
                return "Projet en cours"
              case "EN_ATTENTE":
                return "Instruction en attente"
              case "INSTRUCTION_TERMINEE":
                return "Instruction terminée"
              case "INSTRUCTION_EN_COURS":
                return "En cours d'instruction"
              case "ACCEPTE":
                if (projetAnnuel.typeDossier == "DOSSIER_LABELLISATION") {
                  return "Labellisé"
                } else {
                  return "Renouvelé"
                }
              case "LABELLISATION_EN_DEVENIR":
                return "Labellisé en devenir"
              case "REFUSE":
                if (projetAnnuel.typeDossier == "DOSSIER_LABELLISATION") {
                  return "Labellisation refusée"
                } else {
                  return "Renouvelement refusé"
                }
              default:
                break
            }
            break
        }
    }
    return projetAnnuel.statut
  }

  getProjectConsultActionLabel(projetAnnuel: ProjetAnnuelDTO, loggedUser: UtilisateurDTO): string {
    switch (loggedUser.profil) {
      case "REFERENT":
      case "ENSEIGNANT":
        switch (projetAnnuel.typeDossier) {
          // For PP dossier inscrition
          case "DOSSIER_INSCRIPTION":
            switch (projetAnnuel.statut) {
              case "BROUILLON":
                return "Reprendre l'inscription"
              case "INSTRUCTION_TERMINEE":
              case "INSTRUCTION_EN_COURS":
              case "EN_ATTENTE":
              case "ACCEPTE":
              case "REFUSE":
                return "Consulter l'inscription"
              case "ACCEPTE_SOUS_RESERVE_REFERENT":
                return "Compléter l'inscription"
              default:
                break
            }
            break

          // For PP labellisation
          default:
            break
        }
        return "Consulter"

      default:
        switch (projetAnnuel.typeDossier) {
          // For accompagnateurs dossier inscrition
          case "DOSSIER_INSCRIPTION":
            return "Consulter l'inscription"

          // For accompagnateurs labellisation
          default:
            break
        }
        return "Consulter"
    }
  }

  getLabelForLastLabellisation(projetAnnuel: ProjetAnnuelDTO): string {
    let mentionString = ""
    switch (projetAnnuel.lastEvaluationTypeDossier) {
      case "DOSSIER_LABELLISATION":
        switch (projetAnnuel.lastEvaluationMention) {
          case "REFUSE":
            mentionString = "Labellisation refusée"
            break
          case "LABELLISATION_EN_DEVENIR":
            mentionString = "Labellisé en devenir"
            break
          default:
            mentionString = "Labellisé"
            break
        }
        break

      default:
        switch (projetAnnuel.lastEvaluationMention) {
          case "REFUSE":
            mentionString = "Renouvellement refusée"
            break
          default:
            mentionString = "Renouvelé"
            break
        }
        break
    }
    return (
      mentionString +
      " " +
      ConversionUtils.getAnneeScolaireString(
        projetAnnuel.lastEvaluationAnneeScolaireDebut,
        projetAnnuel.lastEvaluationAnneeScolaireFin
      )
    )
  }
}
