










































































































































































































import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import TableFilterView from "@/views/tables/TableFilterView.vue"
import Column from "@/views/tables/Column"
import {
  EtablissementDTO,
  PaginationOrder,
  ProjetAnnuelDTO,
  UtilisateurDTO,
} from "@/model/bean/GeneratedDTOs"
import { InfoReportingService } from "@/services/log/InfoReportingService"
import { Component, Prop, Watch } from "vue-property-decorator"
import { OrganismeService } from "@/services/rest/organisme/OrganismeService"
import { LoginService } from "@/services/rest/login/LoginService"
import { UsersService } from "@/services/rest/users/UsersService"
import { DialogProgrammatic as Dialog } from "buefy"
import ObfuscationUtils from "@/services/ObfuscationUtils"
import InputWithValidation from "@/views/inputs/InputWithValidation.vue"

@Component({
  components: {
    TableFilterView,
    InputWithValidation,
  },
})
export default class ProjetEnseignantsView extends AbstractTableView {
  @Prop() projetAnnuel: ProjetAnnuelDTO
  @Prop() invitations: string[]
  @Prop() readonly: boolean
  @Prop() showInviteLink: boolean
  @Prop() isPorteurProjet: boolean

  private loginService = LoginService.INSTANCE
  private usersService = UsersService.INSTANCE
  private organismeService = OrganismeService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE

  loggedUser: UtilisateurDTO = new UtilisateurDTO()
  enseignants: Array<UtilisateurDTO> = []
  tableRef = "enseignants"
  hasEnoughValidatedEnseignants = false
  etablissements: Array<EtablissementDTO> = []

  @Watch("projetAnnuel.enseignants")
  projetAnnuelChanged(): void {
    this.reloadData()
  }

  created(): void {
    const loggedUser = this.loginService.getLoggedUser()
    if (!loggedUser || !loggedUser.id) {
      return
    }
    this.loggedUser = loggedUser
  }

  async reloadData(): Promise<void> {
    this.enseignants = JSON.parse(JSON.stringify(this.projetAnnuel.enseignants))
    if (this.projetAnnuel && this.projetAnnuel.etablissements) {
      this.sortEnseigantsAndAddInvitablesFromEtablissement()
    }
    this.isLoading = false
    this.hasEnoughValidatedEnseignants =
      this.projetAnnuel.enseignants.length - this.nbEnseignantEnAttente() > 0
  }

  annuler(enseignant: UtilisateurDTO): void {
    if (this.nbEnseignantEnAttente() > 0) {
      Dialog.confirm({
        message: this.$t(
          "projet.infos_administratives.areYouSureToRemoveInvitationUser2"
        ).toString(),
        confirmText: this.$t("confirmer").toString(),
        cancelText: this.$t("annuler").toString(),
        onConfirm: () => this.removeInvitation(enseignant),
      })
    } else {
      this.infoReportingService.dialog(
        this.$t("projet.infos_administratives.at-least-one-title", ["référent"]).toString(),
        this.$t("projet.infos_administratives.at-least-one-message", ["ce référent"]).toString(),
        "OK",
        () => {
          // Nothing to do
        },
        "is-warning"
      )
    }
  }
  private removeInvitation(enseignant: UtilisateurDTO) {
    this.projetAnnuel.consentementEnAttente = this.projetAnnuel.consentementEnAttente.filter(
      (element) => element !== enseignant.id
    )
    this.removeUser(enseignant)
  }
  async sortEnseigantsAndAddInvitablesFromEtablissement(): Promise<void> {
    // Keep only enseigants that have accepted invitations
    const participatingEnseignants = this.enseignants.filter((ens) => {
      return this.participating(ens) && ens.id && ens.etablissement && ens.etablissement.id
    })
    // Get etablissements of these validated enseignants
    let etablissementIds: string[] = []
    this.etablissements = []
    participatingEnseignants.forEach((ens) => {
      if (
        ens.etablissement &&
        ens.etablissement.id &&
        etablissementIds.indexOf(ens.etablissement.id) < 0
      ) {
        etablissementIds.push(ens.etablissement.id)
        this.etablissements.push(ens.etablissement)
      }
    })
    // Get all enseignants from these etablissements to propose to invite them
    let others = await this.organismeService.getEnseignantsForEtablissements(etablissementIds)
    others = others.filter((other) => !this.enseignants.some((present) => other.id === present.id))

    // Sort
    const participatingEnseignantsIds = participatingEnseignants.map((ens) => ens.id!)
    this.enseignants = this.enseignants.sort((ens1, ens2) => {
      // Me first
      if (ens1.id == this.loggedUser.id) {
        return -1
      }
      if (ens2.id == this.loggedUser.id) {
        return 1
      }

      // Then participating
      const ens1Participating = participatingEnseignantsIds.indexOf(ens1.id!) > -1
      const ens2Participating = participatingEnseignantsIds.indexOf(ens2.id!) > -1
      if (ens1Participating != ens2Participating) {
        return ens1Participating ? -1 : 1
      }

      // Sort per etablissement
      if (
        ens1.etablissement &&
        ens2.etablissement &&
        ens1.etablissement.nom != ens2.etablissement!.nom
      ) {
        return ens1.etablissement!.nom.localeCompare(ens2.etablissement!.nom)
      }

      // Sort alphabetically then
      return ens1.courriel.localeCompare(ens2.courriel)
    })

    this.enseignants = this.enseignants.concat(others)

    // Filtrer les enseignants pour ne conserver que ceux qui n'ont pas le statut "EMAIL_A_VALIDER"
    this.enseignants = this.enseignants.filter(enseignant => enseignant.statutActivation !== "EMAIL_A_VALIDER");

  }

  getInitialColumns(): Array<Column> {
    return [
      {
        field: "nouveau",
        label: this.$t("nouveau").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "etablissement",
        label: this.$t("graePage.membres.etablissement").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "prenom",
        label: this.$t("graePage.membres.prenom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "nom",
        label: this.$t("graePage.membres.nom").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "courriel",
        label: this.$t("graePage.membres.courriel").toString(),
        sortable: true,
        backEndSearchable: true,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "participation",
        label: "",
        sortable: true,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "action",
        label: this.$t("projetPage.actions").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
    ]
  }

  getDefaultSort(): Array<PaginationOrder> {
    const nameSort = new PaginationOrder()
    nameSort.clause = "nom"
    nameSort.desc = true
    return [nameSort]
  }

  showUserDetailsPage(event: Event, id: number): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()
    //For APP to open ProfileModale
    this.$root.$emit("open-profile-modale", true, id)
  }

  dirtifyProject(withRedirect: boolean): void {
    this.$emit("dirtify-project", withRedirect)
  }

  invite(enseignant: UtilisateurDTO): void {
    this.projetAnnuel.enseignants = [...this.projetAnnuel.enseignants, enseignant]
    this.invitations.push(enseignant.courriel)
    this.projetAnnuel.charteFileValid = false
    this.dirtifyProject(false)
  }

  quit(enseignant: UtilisateurDTO): void {
    if (this.projectHasMoreThanOneEnseignant()) {
      Dialog.confirm({
        message: this.$t(
          "projet.infos_administratives.areYouSureToRemoveInvitationUser2"
        ).toString(),
        confirmText: this.$t("confirmer").toString(),
        cancelText: this.$t("annuler").toString(),
        onConfirm: () => this.removeUser(enseignant),
      })
    } else {
      this.infoReportingService.dialog(
        this.$t("projet.infos_administratives.at-least-one-title", ["enseignant"]).toString(),
        this.$t("projet.infos_administratives.at-least-one-message", ["cet enseignant"]).toString(),
        "OK",
        () => {
          // Nothing to do
        },
        "is-warning"
      )
    }
  }

  private removeUser(enseignant: UtilisateurDTO) {
    this.projetAnnuel.enseignants = this.projetAnnuel.enseignants.filter(
      (element) => element.id !== enseignant.id
    )
    this.dirtifyProject(this.loggedUser.id === enseignant.id)
  }

  showInviteButton(toInvite: UtilisateurDTO): boolean {
    return (
      (this.usersService.isPorteurProjet(this.loggedUser) ||
        this.usersService.isAccompagnateurRegional(this.loggedUser) ||
        this.usersService.isAccompagnateurNationalOrAdmin(this.loggedUser)) &&
      !this.isLinked(toInvite)
    )
  }

  showQuitButton(toDelete: UtilisateurDTO): boolean {
    return this.canDeleteEnseignants(toDelete) && !this.invitations.includes(toDelete.courriel)
  }

  private canDeleteEnseignants(toDelete: UtilisateurDTO): boolean {
    return (
      (this.usersService.isPorteurProjet(this.loggedUser) ||
        this.usersService.isAccompagnateurRegional(this.loggedUser) ||
        this.usersService.isAccompagnateurNationalOrAdmin(this.loggedUser)) &&
      this.isLinked(toDelete) &&
      !this.hasConsentementEnAttente(toDelete)
    )
  }

  private isLinked(toDelete: UtilisateurDTO): boolean {
    return this.projetAnnuel.enseignants.some((utilisateur) => utilisateur.id === toDelete.id)
  }

  private hasConsentementEnAttente(toDelete: UtilisateurDTO): boolean {
    return this.projetAnnuel.consentementEnAttente.some((id) => id === toDelete.id)
  }

  private projectHasMoreThanOneEnseignant(): boolean {
    return this.projetAnnuel.enseignants.length - this.nbEnseignantEnAttente() > 1
  }

  private nbEnseignantEnAttente(): number {
    return (
      this.projetAnnuel.consentementEnAttente.filter((id) =>
        this.projetAnnuel.enseignants.some((e) => e.id === id)
      ).length + this.invitations.length
    )
  }

  participating(user: UtilisateurDTO): boolean {
    if (user.id) {
      return (
        this.projetAnnuel.enseignants.some((enseignant) => enseignant.id === user.id) &&
        this.projetAnnuel.consentementEnAttente.indexOf(user.id) === -1 &&
        !this.invitations.includes(user.courriel)
      )
    } else {
      return false
    }
  }

  showRemove(user: UtilisateurDTO): boolean {
    let role = this.loggedUser.profil
    console.log(role)
    const roles = ["SUPER_ADMINISTRATEUR", "ACCOMPAGNATEUR_NATIONAL", "ANIMATEUR_REGIONAL"]
    if (user.id && roles.includes(role)) {
      return this.projetAnnuel.consentementEnAttente.indexOf(user.id) !== -1
    } else {
      return false
    }
  }
  invited(user: UtilisateurDTO): boolean {
    if (user.id) {
      return this.projetAnnuel.consentementEnAttente.indexOf(user.id) !== -1
    } else {
      return false
    }
  }

  isNewEnseignant(enseignantId: string): boolean {
    return this.projetAnnuel.nouveauxActeurs.some((acteur) => enseignantId === acteur.id)
  }

  treatCourriel(user: UtilisateurDTO, forceObfuscation: boolean): string {
    if (this.canSeeCourriel(user) && !forceObfuscation) {
      return user.courriel
    }
    return ObfuscationUtils.obfuscateCourriel(user.courriel)
  }

  canSeeCourriel(user: UtilisateurDTO): boolean {
    return (
      user.acceptNameVisibilityOnProtectedListing ||
      user.id === this.loggedUser.id ||
      ObfuscationUtils.hasRightToSeeCourriel(user, this.loggedUser)
    )
  }

  showEtablissementDetailsPage(et: EtablissementDTO): void {
    this.$router.push("/etablissement/" + et.id + "/details")
  }
}
