









































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import Column from "@/views/tables/Column"
import Activable from "@/views/utils/Activable"
import { FilterDTO } from "@/model/bean/GeneratedDTOs"
import { FilterDTOWithLabel } from "@/views/tables/FilterDTOWithLabel"
import DatePickerWithValidation from "../inputs/DatePickerWithValidation.vue"

/**
 * Abstract class providing methods for sorting, paginating and handling b-tables.
 */
@Component({
  components: { DatePickerWithValidation },
})
export default class TableFilterView extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() columns: [Column]
  // List of selected column
  selectedColumnNames = new Array<string>()
  // Filtered columns matching searched tags in column filtering search
  filteredColumns = new Array<string>()

  // List of active filters
  @Prop() initialFilters: FilterDTO[]

  @Prop({ default: "" }) page: string
  activeFilters = new Array<FilterDTOWithLabel>()
  newFilterField = ""
  newFilterColumn: Column = new Column()
  newFilterValue = ""
  newFilterMustBeNull = "value"
  columnSearch = ""

  @Prop({ default: true }) showExport: boolean
  @Prop({ default: true }) showCustomColumns: boolean
  @Prop({ default: true }) canExportCsv: boolean

  mounted(): void {
    this.columns.forEach((column) => {
      if (column.visible) {
        this.selectedColumnNames.push(column.label)
        this.getFilteredColumns("")
      }
    })
    const filtersCopy: FilterDTO[] = JSON.parse(JSON.stringify(this.initialFilters))
    filtersCopy.forEach((filter) => {
      const filterWithLabel = new FilterDTOWithLabel()
      filterWithLabel.property = filter.property
      const filtered = this.columns.filter((col) => col.field == filter.property)
      filterWithLabel.label = filtered.length ? filtered[0].label : ""
      filterWithLabel.values = filter.values
      this.activeFilters.push(filterWithLabel)
    })
  }

  @Watch("newFilterField")
  newFilterFieldChanged(): void {
    this.newFilterColumn = this.columns.filter((col) => col.field == this.newFilterField)[0]
  }

  getFilteredColumns(search: string): void {
    this.filteredColumns = new Array<string>()
    this.columns.forEach((column) => {
      if (
        column.canBeAdded &&
        this.selectedColumnNames.indexOf(column.label) == -1 &&
        (search == undefined ||
          search == null ||
          !search.toLowerCase ||
          column.label.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0)
      ) {
        this.filteredColumns.push(column.label)
      }
    })
  }

  updateSelectedColumns(addedColumn: string, add: boolean): void {
    if (!addedColumn) {
      return
    }

    const indexColumn = this.selectedColumnNames.indexOf(addedColumn)

    if (add && indexColumn === -1) {
      this.selectedColumnNames.push(addedColumn)
    } else {
      this.selectedColumnNames.splice(indexColumn, 1)
    }
    this.columnSearch = ""
    this.getFilteredColumns("")
  }

  updateVisibleColumns(): void {
    const newVisibleColumns = new Array<Column>()
    this.columns.forEach((column) => {
      const newColumn = JSON.parse(JSON.stringify(column))
      newColumn.visible = this.selectedColumnNames.indexOf(column.label) != -1
      newVisibleColumns.push(newColumn)
    })

    // Close dropdown
    const dropdown = (this.$refs["columns-filter-dropdown"] as unknown) as Activable
    dropdown.isActive = false

    // Warn AbstractTableView that visible columns changed
    this.$emit("update-visible-columns", newVisibleColumns)
  }

  removeFilterValue(property: string, value: string): void {
    const filter = this.activeFilters.filter((filter) => filter.property == property)
    if (filter.length > 0) {
      if (filter[0].values.length > 1) {
        // Only remove value but none filter, other values remaine
        filter[0].values = filter[0].values.filter((val) => val != value)
      } else {
        // Was last value, remove filter from list
        this.activeFilters = this.activeFilters.filter((filter) => filter.property != property)
      }
    }

    // Warn AbstractTableView that filters changed
    this.$emit("update-active-filters", this.activeFilters)
  }

  addActiveFilter(): void {
    if (
      this.newFilterField.length > 0 &&
      (this.newFilterMustBeNull == "null" ||
        this.newFilterValue.length > 0 ||
        this.newFilterField == "dateModification")
    ) {
      // check if there is an already existing filter or create a new one
      const existingFilter = this.activeFilters.filter((filter) => {
        return filter.property == this.newFilterField
      })
      if (existingFilter.length > 0) {
        let filterValues = existingFilter[0].values

        // Push empty string if filter was without any value
        if (filterValues.length == 0) {
          filterValues.push("")
        }

        if (this.newFilterMustBeNull == "value") {
          //
          const item: any =
            this.newFilterField == "dateModification"
              ? new Date(this.newFilterValue).toISOString()
              : this.newFilterValue
          // Ajoute la valeur si pas déjà présente
          if (!filterValues.includes(item)) {
            filterValues.push(item)
          }
        } else {
          filterValues.push("")
        }
      } else {
        const newFilter = new FilterDTOWithLabel()
        newFilter.property = this.newFilterField
        newFilter.label = this.columns.filter((col) => col.field == this.newFilterField)[0].label
        if (this.newFilterMustBeNull == "value") {
          const item =
            this.newFilterField == "dateModification"
              ? new Date(this.newFilterValue).toISOString()
              : this.newFilterValue
          newFilter.values = [(item || "").trim()]
        } else {
          newFilter.values = []
        }
        this.activeFilters.push(newFilter)
      }

      // reset values and close dropdown
      this.newFilterField = ""
      this.newFilterValue = ""
      this.newFilterMustBeNull = "value"
      const dropdown = (this.$refs["columns-values-dropdown"] as unknown) as Activable
      dropdown.isActive = false

      // Warn AbstractTableView that filters changed
      this.$emit("update-active-filters", this.activeFilters)
    }
  }

  convertDateFormat(dateStr: any): string {
    // Assuming dateStr is in the format "mm/dd/yyyy hh:mm:ss"
    const dateParts = dateStr.split("T")[0].split("-")

    const day = dateParts[0]
    const month = dateParts[1]
    const year = dateParts[2]

    // Return in the format "jj/mm/aaaa"
    return `${day}/${month}/${year}`
  }

  exportCSV(): void {
    this.$emit("export-csv")
  }

  exportXLS(): void {
    this.$emit("export-xls")
  }

  exportXLSAll(): void {
    this.$emit("export-xls-all")
  }
}
