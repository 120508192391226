




import i18n from "@/i18n"
import ValidationResult, { TabValidationHint, VALIDATION_TYPES } from "@/model/ValidationResult"
import { Component, Vue, Watch } from "vue-property-decorator"

/**
 * An abstract component used to manage errors displaying in tabs and navigation by url in tabs.
 * Components that wish to extend it, needs to:
 * - initialize numberOfTabs (and currentTabIndex if not 0) in their created method
 * - make sure that the b-tabs v-model is currentTabIndex
 * - use a TabHeaderWithHint in the b-t-item header template with the tabValidationHints index corresponding at the index of the tab item in the tabs
 * - have ValidationObservers in every b-tab-items, referenced by : "observer-x" where x in the index of the tab item in the tabs
 * /!\ the tabs numerotation start at 0 /!\
 */
@Component({})
export default class AbstractFormStepWithTabs extends Vue {
  numberOfTabs = 0
  currentTabIndex = 0
  tabValidationHints: TabValidationHint[] = []

  created(): void {
    for (let i = 0; i < this.numberOfTabs; i++) {
      this.tabValidationHints.push(new TabValidationHint())
    }
  }

  mounted(): void {
    this.setWarnings()
  }

  updated(): void {
    this.setWarnings()
  }

  setTabIndex(tabIndex: number): void {
    this.currentTabIndex = tabIndex
  }

  @Watch("currentTabIndex")
  onTabIndexChanged(): void {
    this.setWarnings()
    this.$emit("tab-index-changed", this.currentTabIndex)
  }

  handleTabWarnings(tabIndex: number): void {
    const observer = this.$refs[`observer-${tabIndex}`]
    if (!observer) {
      return
    }
    //@ts-ignore
    return observer.validateWithInfo().then((validationResult: ValidationResult) => {
      const tabValidationHint = new TabValidationHint()
      const errors = Object.values(validationResult.errors).flat()
      tabValidationHint.errorsCount = errors.length
      tabValidationHint.type = VALIDATION_TYPES.WARNING

      if (errors.some((err) => !this.isWarning(err as string))) {
        tabValidationHint.type = VALIDATION_TYPES.DANGER
      }
      this.$set(this.tabValidationHints, tabIndex, tabValidationHint)
    })
  }

  setWarnings(): void {
    for (let i = 0; i < this.numberOfTabs; i++) {
      this.handleTabWarnings(i)
    }
  }

  /**
   * Indicates if given validation message descriptes a warning (will be considered as error otherwise)
   */
  isWarning(errorMessage: string): boolean {
    return errorMessage == i18n.t("validation-input-invalid-required")
  }

  getNumberOfTabs(): number {
    return this.numberOfTabs
  }

  lastTabDisplayed(): boolean {
    return this.currentTabIndex === this.numberOfTabs - 1
  }

  firstTabDisplayed(): boolean {
    return this.currentTabIndex === 0
  }

  goToNextTab(): void {
    const nextIndex = this.lastTabDisplayed() ? this.currentTabIndex : this.currentTabIndex + 1
    this.setTabIndex(nextIndex)
  }

  goToPreviousTab(): void {
    const previousIndex = this.firstTabDisplayed() ? this.currentTabIndex : this.currentTabIndex - 1
    this.setTabIndex(previousIndex)
  }

  getNumberOfWarningsOnAllTabs(): number {
    if (!this.tabValidationHints || this.tabValidationHints.length === 0) {
      return 0
    }
    let errors = 0
    this.tabValidationHints.forEach((element) => {
      errors += element.errorsCount
    })
    return errors
  }

  getNumberOfWarningsOnAllTabsForStructureDetail(): number {
    this.tabValidationHints = []
    this.setWarnings();
    if (!this.tabValidationHints || this.tabValidationHints.length === 0) {
      return 0
    }
    let errors = 0
    this.tabValidationHints.forEach((element) => {
      errors += element.errorsCount
    })
    return errors
  }
}
