
































import { Component, Prop, Vue } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import BookmarkCard from "../generic_components/BookmarkCard.vue"
import { INVITATION_STATUS, TAG_TYPES_FOR_BOOKMARK_CARD } from "@/model/bean/EnumHelper"
import { ProjetAnnuelDTO } from "@/model/bean/GeneratedDTOs"
import { ProjectService } from "@/services/rest/project/ProjectService"

@Component({
  components: { TitleView, ContentView, BookmarkCard },
})
export default class PendingProjectCard extends Vue {
  @Prop() project: ProjetAnnuelDTO
  @Prop({ default: "" }) loggedUserId: string
  @Prop({ default: true }) sendToServer: boolean

  tagType = TAG_TYPES_FOR_BOOKMARK_CARD.INVITATION
  invitationStatus = INVITATION_STATUS.PENDING

  private projectService = ProjectService.INSTANCE

  async acceptInvitation(): Promise<void> {
    this.invitationStatus = INVITATION_STATUS.ACCEPTED
    this.project.consentementEnAttente.splice(
      this.project.consentementEnAttente.indexOf(this.loggedUserId),
      1
    )
    if (this.project.consentementValide === null) {
      this.project.consentementValide = []
    }
    this.project.consentementValide.push(this.loggedUserId)
    if (this.sendToServer) {
      await this.projectService.sendAnswerAboutPendingInvitation(this.project, this.loggedUserId)
      this.$emit("invitation-processed")
    }
    window.location.reload();
  }

  async declineInvitation(): Promise<void> {
    this.invitationStatus = INVITATION_STATUS.DECLINED
    this.project.consentementEnAttente.splice(
      this.project.consentementEnAttente.indexOf(this.loggedUserId),
      1
    )
    if (this.sendToServer) {
      await this.projectService.sendAnswerAboutPendingInvitation(this.project, this.loggedUserId)
      this.$emit("invitation-processed")
    }
    window.location.reload();
  }
}
