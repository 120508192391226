



























































import { Component, Prop } from "vue-property-decorator"
import TitleView from "@/views/commons/TitleView.vue"
import { ModificationPPDTO, PaginationOrder, UtilisateurDTO, ModificationsPPResponseDTO } from "@/model/bean/GeneratedDTOs"
import AbstractTableView from "@/views/tables/AbstractTableView.vue"
import TableFilterView from "@/views/tables/TableFilterView.vue"
import Column from "@/views/tables/Column"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"
import BookmarkCard from "@/views/generic_components/BookmarkCard.vue"
import { EnumHelper } from "@/model/bean/EnumHelper"
import { GraeService } from "@/services/rest/grae/GraeService"
import { UtilisateurModifieDTO } from "../../model/bean/GeneratedDTOs"
import { format } from "date-fns"


export type DataModifPP = {
  nomProjet: string,
  nomEtablissement: Array<string>,
  uai: Array<string>,
  actions: Array<string>
  projet: string,
  projetAnnuel: string
}

@Component({
  components: {
    PageView,
    ContentView,
    TitleView,
    TableFilterView,
    BookmarkCard,
  },
})
export default class ModifProjetView extends AbstractTableView {
  @Prop() graeId: string
  @Prop() loggedUser: UtilisateurDTO
  @Prop({ default: false }) readonly: boolean

  private graeService = GraeService.INSTANCE
  list:  Array<DataModifPP> = []
  prevListActions: Map<number, string> = new Map()

  async reloadData(): Promise<void> {
    this.isLoading = true
    try {
      
      const id = this.$route.params.graeId
      const response: ModificationsPPResponseDTO = await this.graeService.getModifsPP(id)
      
      const data: Array<DataModifPP> = []
      response.modificationsPPDTO.forEach( (el: ModificationPPDTO) => {
        const userModifieDTO: DataModifPP = {
          nomProjet: el.nomProjet,
          nomEtablissement: el.nomEtablissement,
          uai: el.uai,
          actions: this.formatValueAction(el),
          projet: el.projet,
          projetAnnuel: el.projetAnnuel
        }
        data.push(userModifieDTO)
      }) 

      this.list = data
      this.total = data.length
    } catch (error) {
      console.log(error)
    }
    this.isLoading = false
  }

  getInitialColumns(): Array<Column> {
    return [
      {
        field: "nomProjet",
        label: this.$t("graePage.nomProjet").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "nomEtablissement",
        label: this.$t("graePage.nomEtablissement").toString(),
        sortable: false,
        backEndSearchable: true,
        visible: true,
        custom: false,
        canBeAdded: true,
      },
      {
        field: "uai",
        label: this.$t("graePage.uai").toString(),
        tooltip: this.$t("graePage.uai").toString(),
        sortable: false,
        backEndSearchable: true,
        possibleSearchValues: EnumHelper.allProfilsUtilisateur,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
      {
        field: "action",
        label: this.$t("modifProjet.modification").toString(),
        sortable: false,
        backEndSearchable: false,
        visible: true,
        custom: true,
        canBeAdded: true,
      },
    ]
  }

  formatValueLineAction(row: UtilisateurModifieDTO, typeAction: string): string {
    return `${typeAction} ${row.prenom} ${row.nom} - ${format(
      row.lastDateModification,
      "DD/MM/YYYY HH:mm:ss",
    )}`
  }

  getNextMapIndex(date: Date): number {
    let index: number = new Date(date).getTime();
    do{
      index = index + 1 
    }while(this.prevListActions.has(index))
    return index
  } 

  formatValueAction(modificationPP: ModificationPPDTO): Array<string> {
    this.prevListActions = new Map()
    const listActions: Array<string> = []

    if (modificationPP.ajoutEnseignants.length > 0) {
      modificationPP.ajoutEnseignants.forEach((el: UtilisateurModifieDTO) => 
        this.prevListActions.set(this.getNextMapIndex(el.lastDateModification),this.formatValueLineAction(el, "ajout enseignant"))
      )
    }

    if (modificationPP.retraitEnseigant.length > 0) {
      modificationPP.retraitEnseigant.forEach((el: UtilisateurModifieDTO) =>
        this.prevListActions.set(this.getNextMapIndex(el.lastDateModification), this.formatValueLineAction(el, "retrait enseignant"))
      )
    }

    if (modificationPP.ajoutReferent.length > 0) {
      modificationPP.ajoutReferent.forEach((el: UtilisateurModifieDTO) =>
        this.prevListActions.set(this.getNextMapIndex(el.lastDateModification), this.formatValueLineAction(el, "ajout référent"))
      )
    }

    if (modificationPP.retraitReferent.length > 0) {
      modificationPP.retraitReferent.forEach((el: UtilisateurModifieDTO) =>
        this.prevListActions.set(this.getNextMapIndex(el.lastDateModification), this.formatValueLineAction(el, "retrait référent"))
      )
    }

    // Tri de la liste
    const sorted = new Map(Array.from(this.prevListActions).sort(([a], [b]) => a > b ? -1 : a < b ? 1 : 0));

    // Ajout dans la liste définitive
    sorted.forEach( el => listActions.push(el))

    return listActions
  }

  getDefaultSort(): Array<PaginationOrder> {
    const nameSort = new PaginationOrder()
    nameSort.clause = "nomProjet"
    nameSort.desc = true
    const profilSort = new PaginationOrder()
    profilSort.clause = "nomEtablissement"
    profilSort.desc = true
    return [nameSort, profilSort]
  }

  showUserDetailsPage(event: Event, row: any): void {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation()

    const url = `/project/${row["projet"]}/${row["projetAnnuel"]}/historique/0`

    this.$router.push(url)
    
    //For APP to open ProfileModale
  }
}
