var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field" },
    [
      _vm.columns.filter(function(col) {
        return col.backEndSearchable
      }).length > 0
        ? _c(
            "div",
            { staticClass: "is-pulled-left" },
            [
              _c(
                "b-dropdown",
                {
                  ref: "columns-values-dropdown",
                  attrs: {
                    "aria-role": "list",
                    position: "is-bottom-right",
                    "append-to-body": ""
                  }
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button is-primary",
                      attrs: { slot: "trigger", "icon-right": "angle-down" },
                      slot: "trigger"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("table-filter.filter-columns")) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      staticClass: "columns-filter-new",
                      attrs: { "aria-role": "listitem", custom: "" }
                    },
                    [
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: _vm.$t("table-filter.new-column-name")
                          }
                        },
                        [
                          _c(
                            "b-select",
                            {
                              attrs: {
                                expanded: "",
                                placeholder: _vm.$t(
                                  "table-filter.new-column-name-placeholder"
                                )
                              },
                              model: {
                                value: _vm.newFilterField,
                                callback: function($$v) {
                                  _vm.newFilterField = $$v
                                },
                                expression: "newFilterField"
                              }
                            },
                            _vm._l(
                              _vm.columns.filter(function(col) {
                                return col.backEndSearchable
                              }),
                              function(column) {
                                return _c(
                                  "option",
                                  {
                                    key: column.label,
                                    domProps: { value: column.field }
                                  },
                                  [_vm._v(" " + _vm._s(column.label) + " ")]
                                )
                              }
                            ),
                            0
                          )
                        ],
                        1
                      ),
                      _vm.newFilterField.length > 0
                        ? _c(
                            "b-radio",
                            {
                              staticClass: "filter-value-radio",
                              attrs: { "native-value": "null" },
                              model: {
                                value: _vm.newFilterMustBeNull,
                                callback: function($$v) {
                                  _vm.newFilterMustBeNull = $$v
                                },
                                expression: "newFilterMustBeNull"
                              }
                            },
                            [
                              _c("b-field", {
                                attrs: { label: _vm.$t("table-filter.null") }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.newFilterField.length > 0 &&
                      _vm.newFilterField != "dateModification"
                        ? _c(
                            "b-radio",
                            {
                              staticClass: "filter-value-radio",
                              attrs: { "native-value": "value" },
                              model: {
                                value: _vm.newFilterMustBeNull,
                                callback: function($$v) {
                                  _vm.newFilterMustBeNull = $$v
                                },
                                expression: "newFilterMustBeNull"
                              }
                            },
                            [
                              _c("b-field", {
                                attrs: {
                                  label: _vm.$t("table-filter.value-equals")
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.newFilterField.length > 0 &&
                      _vm.newFilterField == "dateModification"
                        ? _c(
                            "b-radio",
                            {
                              staticClass: "filter-value-radio",
                              attrs: { "native-value": "value" },
                              model: {
                                value: _vm.newFilterMustBeNull,
                                callback: function($$v) {
                                  _vm.newFilterMustBeNull = $$v
                                },
                                expression: "newFilterMustBeNull"
                              }
                            },
                            [
                              _c("b-field", {
                                attrs: {
                                  label: _vm.$t(
                                    "table-filter.value-equals-to-date"
                                  )
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.newFilterField.length > 0 &&
                      !_vm.newFilterColumn.possibleSearchValues &&
                      _vm.newFilterField != "dateModification"
                        ? _c("b-input", {
                            attrs: {
                              disabled: _vm.newFilterMustBeNull == "null",
                              placeholder: _vm.$t(
                                "table-filter.new-column-value-placeholder"
                              )
                            },
                            model: {
                              value: _vm.newFilterValue,
                              callback: function($$v) {
                                _vm.newFilterValue = $$v
                              },
                              expression: "newFilterValue"
                            }
                          })
                        : _vm._e(),
                      _vm.newFilterField.length > 0 &&
                      !_vm.newFilterColumn.possibleSearchValues &&
                      _vm.newFilterField == "dateModification"
                        ? _c("DatePickerWithValidation", {
                            attrs: {
                              vid: "dateModification",
                              rules: "required",
                              placeholder: _vm.$t(
                                "table-filter.value-equals-to-date"
                              )
                            },
                            model: {
                              value: _vm.newFilterValue,
                              callback: function($$v) {
                                _vm.newFilterValue = $$v
                              },
                              expression: "newFilterValue"
                            }
                          })
                        : _vm._e(),
                      _vm.newFilterField.length > 0 &&
                      _vm.newFilterColumn.possibleSearchValues &&
                      _vm.newFilterColumn.possibleSearchValues.length > 0
                        ? _c(
                            "b-select",
                            {
                              attrs: {
                                placeholder:
                                  "table-filter.new-column-value-placeholder"
                              },
                              model: {
                                value: _vm.newFilterValue,
                                callback: function($$v) {
                                  _vm.newFilterValue = $$v
                                },
                                expression: "newFilterValue"
                              }
                            },
                            _vm._l(
                              _vm.newFilterColumn.possibleSearchValues,
                              function(searchValue) {
                                return _c(
                                  "option",
                                  {
                                    key: searchValue,
                                    domProps: { value: searchValue }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t(searchValue)) + " "
                                    )
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _c("br"),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled:
                              _vm.newFilterField.length == 0 ||
                              (_vm.newFilterMustBeNull == "value" &&
                                _vm.newFilterValue.length == 0),
                            type: "is-info"
                          },
                          on: { click: _vm.addActiveFilter }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("table-filter.add-filter")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showExport
                ? _c(
                    "b-dropdown",
                    {
                      ref: "columns-export-dropdown",
                      attrs: {
                        "aria-role": "list",
                        position: "is-bottom-right",
                        "append-to-body": ""
                      }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "button is-light",
                          attrs: {
                            slot: "trigger",
                            "icon-right": "angle-down"
                          },
                          slot: "trigger"
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("table-filter.export")) + " "
                          )
                        ]
                      ),
                      _vm.canExportCsv
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: { "aria-role": "listitem" },
                              on: { click: _vm.exportCSV }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("table-filter.export-csv")) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.page != "userList"
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: { "aria-role": "listitem" },
                              on: { click: _vm.exportXLS }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("table-filter.export-xls")) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      (_vm.page = "userList")
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: { "aria-role": "listitem" },
                              on: { click: _vm.exportXLS }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("table-filter.export-xls-userList")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      !_vm.canExportCsv
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: { "aria-role": "listitem" },
                              on: { click: _vm.exportXLSAll }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("table-filter.export-xls-all")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._t("default")
            ],
            2
          )
        : _vm._e(),
      _vm.showCustomColumns
        ? _c(
            "div",
            { staticClass: "is-pulled-right" },
            [
              _c(
                "b-dropdown",
                {
                  ref: "columns-filter-dropdown",
                  attrs: {
                    "aria-role": "list",
                    position: "is-bottom-left",
                    "append-to-body": "",
                    "close-on-click": true,
                    "can-close": false
                  }
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button is-primary",
                      attrs: { slot: "trigger", "icon-right": "angle-down" },
                      slot: "trigger"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("table-filter.customize-columns")) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      staticClass: "columns-list",
                      attrs: { "aria-role": "listitem", custom: "" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "columns-list-input" },
                        [
                          _c("b-field", {
                            attrs: {
                              label: _vm.$t(
                                "table-filter.customize-columns-label"
                              )
                            }
                          }),
                          _c("div", { staticClass: "inputNotice mb-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "table-filter.customize-columns-input-notice",
                                    [_vm.filteredColumns.length]
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c("b-autocomplete", {
                            attrs: {
                              "open-on-focus": "",
                              placeholder: _vm.$t(
                                "table-filter.customize-columns-placeholder"
                              ),
                              data: _vm.filteredColumns,
                              icon: "search",
                              clearable: "",
                              "clear-on-select": ""
                            },
                            on: {
                              select: function(option) {
                                return _vm.updateSelectedColumns(option, true)
                              },
                              typing: function(option) {
                                return _vm.getFilteredColumns(option)
                              }
                            },
                            model: {
                              value: _vm.columnSearch,
                              callback: function($$v) {
                                _vm.columnSearch = $$v
                              },
                              expression: "columnSearch"
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "columns-list-tags" },
                            _vm._l(_vm.selectedColumnNames, function(
                              selectedColumn
                            ) {
                              return _c("div", { key: selectedColumn }, [
                                _c(
                                  "div",
                                  { staticClass: "control" },
                                  [
                                    _c(
                                      "b-tag",
                                      {
                                        attrs: {
                                          type: "is-light",
                                          attached: "",
                                          "aria-close-label": _vm.$t(
                                            "close-generic"
                                          ),
                                          closable: ""
                                        },
                                        on: {
                                          close: function($event) {
                                            return _vm.updateSelectedColumns(
                                              selectedColumn,
                                              false
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(selectedColumn) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { type: "is-info" },
                          on: { click: _vm.updateVisibleColumns }
                        },
                        [_vm._v(_vm._s(_vm.$t("close-generic")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("br"),
      _c("br"),
      _vm.activeFilters.length > 0
        ? _c(
            "b-field",
            { attrs: { grouped: "", "group-multiline": "" } },
            _vm._l(_vm.activeFilters, function(filter) {
              return _c(
                "div",
                { key: filter.field, staticClass: "control" },
                [
                  _c(
                    "b-taglist",
                    { staticClass: "filters-list", attrs: { attached: "" } },
                    [
                      _c("b-tag", { attrs: { type: "is-dark" } }, [
                        _vm._v(_vm._s(filter.label))
                      ]),
                      _c(
                        "b-tag",
                        { attrs: { type: "is-grey" } },
                        [
                          filter.values.length == 0
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("table-filter.none")) +
                                      " "
                                  ),
                                  _c(
                                    "b-tag",
                                    {
                                      staticClass: "close",
                                      attrs: { type: "is-grey" },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.removeFilterValue(
                                            filter.property,
                                            _vm.value
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("b-icon", { attrs: { icon: "times" } })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(filter.values, function(value, i) {
                            return _c(
                              "span",
                              { key: value },
                              [
                                value.length > 1 &&
                                filter.property != "dateModification"
                                  ? _c("span", [
                                      _vm._v(" " + _vm._s(_vm.$t(value)) + " ")
                                    ])
                                  : _vm._e(),
                                value.length > 1 &&
                                filter.property === "dateModification"
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.convertDateFormat(value)) +
                                          " "
                                      )
                                    ])
                                  : _vm._e(),
                                _c(
                                  "b-tag",
                                  {
                                    staticClass: "close",
                                    attrs: { type: "is-grey" },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.removeFilterValue(
                                          filter.property,
                                          value
                                        )
                                      }
                                    }
                                  },
                                  [_c("b-icon", { attrs: { icon: "times" } })],
                                  1
                                ),
                                i < filter.values.length - 1
                                  ? _c("span", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.$t("table-filter.or"))
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }