







































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import PageView from "@/views/commons/PageView.vue"
import ContentView from "@/views/commons/ContentView.vue"

import { GraeDTO, GraeStatsDTO, UtilisateurDTO } from "@/model/bean/GeneratedDTOs"
import { Filters } from "@/model/bean/Filters"
import { StatService } from "@/services/rest/stats/StatService"
import { BackToPreviousScreenItem, ContextMenu } from "@/views/menus/MenuItems"
import { GraeService } from "@/services/rest/grae/GraeService"
import { UsersService } from "@/services/rest/users/UsersService"
import { InfoReportingService } from "@/services/log/InfoReportingService"

@Component({
  components: {
    PageView,
    ContentView,
  },
})
export default class IndicateursView extends Vue {
  @Prop() grae: GraeDTO

  private statsService = StatService.INSTANCE
  private graeService = GraeService.INSTANCE
  private usersService = UsersService.INSTANCE
  private infoReportingService = InfoReportingService.INSTANCE
  graeStats = new GraeStatsDTO()
  contact = new UtilisateurDTO()

  dasharray = "100 0"

  previousYearAvailable = true
  nextYearAvailable = true
  anneesScolaires: Array<string> = []
  selectedAnneeScolaire = ""

  brouillonFilters = JSON.stringify(
    Filters.asFilters([
      { property: "typeDossier", values: ["DOSSIER_INSCRIPTION"] },
      { property: "statut", values: ["BROUILLON"] },
    ])
  )

  inscriptionsFilter = JSON.stringify(
    Filters.asFilters([
      { property: "inscriptionThisYear", values: ["true"] },
      { property: "projet.etat", values: ["ACTIF","EN_COURS_DE_CREATION"] },
    ])
  )

  get labellisesFilters(): string {
    return JSON.stringify(
      Filters.asFilters([
        { property: "typeDossier", values: ["DOSSIER_LABELLISATION"] },
        { property: "statut", values: ["ACCEPTE", "LABELLISATION_EN_DEVENIR"] },
        { property: "anneeScolaire", values: [this.graeStats.anneeScolaire] },
      ])
    )
  }

  get renouvellesFilters(): string {
    return JSON.stringify(
      Filters.asFilters([
        {
          property: "typeDossier",
          values: ["DOSSIER_RENOUVELLEMENT", "DOSSIER_RENOUVELLEMENT_LIGHT"],
        },
        { property: "statut", values: ["ACCEPTE"] },
        { property: "anneeScolaire", values: [this.graeStats.anneeScolaire] },
      ])
    )
  }

  get refusesFilters(): string {
    return JSON.stringify(
      Filters.asFilters([
        {
          property: "typeDossier",
          values: [
            "DOSSIER_LABELLISATION",
            "DOSSIER_RENOUVELLEMENT",
            "DOSSIER_RENOUVELLEMENT_LIGHT",
          ],
        },
        { property: "statut", values: ["REFUSE"] },
        { property: "anneeScolaire", values: [this.graeStats.anneeScolaire] },
      ])
    )
  }

  async mounted(): Promise<void> {
    await this.loadAnneesScolaires()
  }

  async getStatsForGrae(): Promise<void> {
    if (this.grae && this.grae.id) {
      if (this.selectedAnneeScolaire === this.anneesScolaires[0]) {
        this.graeStats = await this.statsService.graeStats(this.grae.id)
      } else {
        this.graeStats = await this.statsService.graeStatsForAnnee(
          this.grae.id,
          this.selectedAnneeScolaire
        )
      }
      if (this.grae.contactId) {
        try {
          this.contact = await this.usersService.getUserProfile(this.grae.contactId)
        } catch (e) {
          this.infoReportingService.error("Erreur rencontrée sur le contact", e as Error)
        }
      } else {
        this.contact.courriel = this.$t("default.email").toString()
        this.contact.telephone = this.$t("default.phone").toString()
        this.contact.nom = this.$t("default.profil").toString()
      }
      this.refreshDonutChart()
    }
  }

  refreshContextMenu(showBackToListMenuEntry: boolean): void {
    const menuItems = []
    if (this.grae) {
      //const graeId = this.grae.id
      // Back to previous screen (if comming from list)
      if (showBackToListMenuEntry) {
        menuItems.push(new BackToPreviousScreenItem())
      }

      const possibleMenuParents = ["/grae-list", "/grae/details/"]
      this.$root.$emit("contextual-menu-changed", new ContextMenu(possibleMenuParents, menuItems))
    }
  }

  refreshDonutChart(): void {
    const amePercent = (100 * this.graeStats.nbATE) / (this.graeStats.nbATE + this.graeStats.nbAME)
    this.dasharray = amePercent + " " + (100 - amePercent)
  }

  previousYear(): void {
    const thisYearIndex = this.anneesScolaires.indexOf(this.selectedAnneeScolaire)
    const previousYear = this.anneesScolaires[thisYearIndex + 1]

    this.selectedAnneeScolaire = previousYear
    this.getStatsForGrae()
  }

  nextYear(): void {
    const thisYearIndex = this.anneesScolaires.indexOf(this.selectedAnneeScolaire)
    const nextYear = this.anneesScolaires[thisYearIndex - 1]

    this.selectedAnneeScolaire = nextYear
    this.getStatsForGrae()
  }

  goToYear(): void {
    this.getStatsForGrae()
  }

  @Watch("grae")
  async loadAnneesScolaires(): Promise<void> {
    if (this.grae && this.grae.id) {
      this.anneesScolaires = await this.graeService.getAnneesScolairesForGRAE(this.grae.id)
    } else {
      this.anneesScolaires = await this.graeService.getAnneesScolaires()
    }

    this.selectedAnneeScolaire = this.anneesScolaires[0]
    this.goToYear()
  }
}
